import { RichTextTypography, YoutubeEmbed } from '@components/atoms';
import { SlideContentWrapper } from '../SlideContentWrapper';
import { ActivitySubtype, ActivityType } from '@domain/constants';

interface RenderVideoSlideProps {
  title: string;
  videoURL: string;
  type: ActivityType;
  subtype: ActivitySubtype;
  textContent?: string;
}

export const RenderVideoSlide = ({
  title,
  videoURL,
  type,
  subtype,
  textContent,
}: RenderVideoSlideProps) => {
  return (
    <SlideContentWrapper title={title} type={type} subtype={subtype}>
      <YoutubeEmbed url={videoURL} className='mb-8 !h-56' />
      {textContent && <RichTextTypography description={textContent} />}
    </SlideContentWrapper>
  );
};
