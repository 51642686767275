import {
  Content,
  STUDENT_LATEST_RESPONSE_STORAGE_KEY,
  UserRole,
} from '@domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import {
  FavoriteAnswer,
  OpenQuestionResponses,
  SlideTextInput,
} from '@components/molecules';
import { useOpenQuestion } from '@hooks/index';
import { Button } from '@components/atoms';
import { useContext, useEffect, useState } from 'react';
import { SendButtonContext } from '@context/index';

interface RenderOpenQuestionActivityProps {
  slide: Content;
  role: UserRole;
  channel: string;
  showResults: boolean;
  onClickNext: () => void;
}

interface RenderOpenQuestionActivityContentProps {
  showChoiceAward: boolean;
  answerSubmitted: boolean;
  setAnswerSubmitted: (answerSubmitted: boolean) => void;
  role: UserRole;
  slide: Content;
  sendOpenQuestionResponse: (answer: string) => void;
  responses: Record<string, string>[];
  sendShowChoiceAward: (answer: boolean) => void;
  onClickNext: () => void;
  handleLike: (responseIndex: number) => void;
  sendFavoriteResponses: (responses: Record<string, string>[]) => void;
  favoriteResponses: Record<string, string>[] | undefined;
}

const RenderOpenQuestionContent = ({
  showChoiceAward,
  answerSubmitted,
  setAnswerSubmitted,
  role,
  slide,
  sendOpenQuestionResponse,
  responses,
  handleLike,
  sendShowChoiceAward,
  onClickNext,
  sendFavoriteResponses,
  favoriteResponses,
}: RenderOpenQuestionActivityContentProps) => {
  const { setSendButtonState } = useContext(SendButtonContext);

  useEffect(() => {
    if (showChoiceAward) {
      setSendButtonState({
        loading: false,
        disabled: false,
        answered: false,
        hide: true,
      });
    }
  }, [showChoiceAward, setSendButtonState]);

  if (showChoiceAward) {
    return (
      <FavoriteAnswer
        onClickNext={onClickNext}
        favoriteResponses={favoriteResponses}
      />
    );
  }

  if (answerSubmitted || role === UserRole.CATECHIST) {
    const getFavoriteResponses = (responses: Record<string, string>[]) => {
      const allZeroLikes = responses.every(
        response => response.likes.count === 0
      );
      if (allZeroLikes) return [];

      const maxLikes = Math.max(
        ...responses.map(response => response.likes.count)
      );

      const topFavoriteResponses = responses.filter(
        response => response.likes.count === maxLikes
      );

      return topFavoriteResponses;
    };

    const handleShowFavoriteAnswer = async () => {
      await sendFavoriteResponses(getFavoriteResponses(responses));
      await sendShowChoiceAward(true);
    };

    return (
      <div>
        <OpenQuestionResponses
          handleLike={handleLike}
          role={role}
          responses={responses}
        />
        {role === UserRole.CATECHIST && (
          <div className='fixed bottom-4 left-0 right-0 z-10 mt-4 flex w-full items-center justify-center'>
            <Button
              style='primary'
              text='Next'
              onClick={handleShowFavoriteAnswer}
              className='!w-[90%]'
            />
          </div>
        )}
      </div>
    );
  }

  if (!answerSubmitted && role === UserRole.STUDENT) {
    const handleSubmitResponse = async (answer: string) => {
      await sendOpenQuestionResponse(answer);
      setAnswerSubmitted(true);
    };

    return (
      <SlideTextInput
        isTextarea
        activitySubtype={slide.subtype}
        onSubmit={handleSubmitResponse}
      />
    );
  }
};

export const RenderOpenQuestionActivity = ({
  slide,
  role,
  channel,
  onClickNext,
}: RenderOpenQuestionActivityProps) => {
  const {
    responses,
    sendOpenQuestionResponse,
    sendShowChoiceAward,
    showChoiceAward,
    handleLike,
    sendFavoriteResponses,
    favoriteResponses,
  } = useOpenQuestion({
    channel,
    slideId: slide.id,
  });

  const [answerSubmitted, setAnswerSubmitted] = useState(false);

  useEffect(() => {
    setAnswerSubmitted(false);
    localStorage.removeItem(STUDENT_LATEST_RESPONSE_STORAGE_KEY);
  }, [slide.id]);

  return (
    <SlideContentWrapper
      title={slide.openQuestion?.question}
      type={slide.type}
      subtype={slide.subtype}
    >
      <RenderOpenQuestionContent
        answerSubmitted={answerSubmitted}
        setAnswerSubmitted={setAnswerSubmitted}
        showChoiceAward={showChoiceAward}
        role={role}
        slide={slide}
        sendOpenQuestionResponse={sendOpenQuestionResponse}
        sendShowChoiceAward={sendShowChoiceAward}
        responses={responses}
        handleLike={handleLike}
        onClickNext={onClickNext}
        sendFavoriteResponses={sendFavoriteResponses}
        favoriteResponses={favoriteResponses}
      />
    </SlideContentWrapper>
  );
};
