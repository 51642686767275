import { Button } from '@components/atoms';
import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { SlideAnswerSubmitted } from '../SlideAnswerSubmitted/SlideAnswerSubmitted';
import { SendButtonContext } from '@context/index';
import { MdCheck, MdClear } from 'react-icons/md';
import { motion } from 'framer-motion';
import Confetti from 'react-confetti';
import { trackGA4Events } from '@data/utils';
import {
  ActivityAnalytics,
  activityDoneLabel,
  ActivitySubtype,
} from '@domain/constants';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';

interface PollActivityInputProps {
  options: string[];
  containerStyle?: string;
  onClickOption: (option: string) => void;
  correctAnswer?: string;
  selectedOption: string;
  setSelectedOption: (option: string) => void;
}

const selectedStyles =
  'border-2 border-primary-400 hover:border-primary-400 hover:border-2 !font-bold disabled:!font-base !text-primary-400 hover:text-primary-400 disabled:!border disabled:border-primary-400 disabled:text-primary-300 ';
const correctAnswerStyles = {
  correct:
    'border-2 border-secondary-semantic-success-400 disabled:!font-base !font-bold disabled:!border-2 disabled:border-secondary-semantic-success-400',
  wrong:
    'border-2 border-secondary-semantic-error-400 disabled:!font-base !font-bold disabled:!border-2 disabled:border-secondary-semantic-error-400',
};

export const PollActivityInput = ({
  options,
  onClickOption,
  correctAnswer,
  selectedOption,
  setSelectedOption,
}: PollActivityInputProps) => {
  const { setSendButtonState, sendButtonState } = useContext(SendButtonContext);
  const userId = useSelector(UserSelectors.getId);

  const handleSubmitOption = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      try {
        setSendButtonState(state => ({
          ...state,
          loading: true,
        }));
        event.preventDefault();
        onClickOption(selectedOption);

        if (correctAnswer) {
          trackGA4Events(
            ActivityAnalytics.categories.activityDone,
            ActivityAnalytics.actions.activity,
            activityDoneLabel(
              new Date().toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }),
              userId.toString(),
              ActivitySubtype.QUIZ
            )
          );
        } else {
          trackGA4Events(
            ActivityAnalytics.categories.activityDone,
            ActivityAnalytics.actions.activity,
            activityDoneLabel(
              new Date().toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }),
              userId.toString(),
              ActivitySubtype.POLL
            )
          );
        }

        setSendButtonState({
          loading: false,
          disabled: true,
          answered: true,
        });
      } catch (error) {
        setSendButtonState({
          loading: false,
          disabled: false,
          answered: false,
        });
        throw error;
      }
    },
    [selectedOption, onClickOption, setSendButtonState]
  );

  useEffect(() => {
    if (!sendButtonState.answered) {
      setSendButtonState(state => ({
        ...state,
        disabled: !selectedOption,
      }));
    }
  }, [selectedOption, setSendButtonState]);

  const getButtonClassName = useCallback(
    (option: string) => {
      if (correctAnswer) {
        if (sendButtonState.answered && option === selectedOption) {
          if (correctAnswer === option) {
            return correctAnswerStyles.correct;
          }
          return correctAnswerStyles.wrong;
        }
      }

      return selectedOption === option ? selectedStyles : '';
    },
    [selectedOption, sendButtonState.answered, correctAnswer]
  );

  const [triggerConfetti, setTriggerConfetti] = useState(false);
  const confettiRef = useRef<HTMLDivElement>(null);
  const [confettiDimensions, setConfettiDimensions] = useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (confettiRef.current) {
      const rect = confettiRef.current.getBoundingClientRect();
      setConfettiDimensions({
        width: rect.width,
        height: rect.height,
        x: rect.left,
        y: rect.top,
      });
    }
  }, [triggerConfetti]);

  return (
    <div className='flex flex-col gap-6'>
      <form
        className='flex flex-col gap-2'
        onSubmit={e => handleSubmitOption(e)}
        id='slide-form'
      >
        {options.map((option, index) => {
          if (!correctAnswer || !sendButtonState.answered) {
            return (
              // <motion.div
              //   key={index}
              //   initial={{ x: index % 2 === 0 ? '-100vw' : '100vw', opacity: 0 }} // Alternates entry from left and right
              //   animate={{ x: 0, opacity: 1 }} // Moves to center with a fade-in effect
              //   transition={{
              //     type: 'spring',
              //     stiffness: 100,
              //     damping: 15,
              //     mass: 0.5,
              //     delay: index * 0.2, // Staggers the animation of each option
              //   }}
              // >
              <>
                {/* <Confetti /> */}
                <Confetti
                  width={confettiDimensions.width}
                  height={confettiDimensions.height}
                  recycle={false}
                  numberOfPieces={200}
                  gravity={0.3}
                  initialVelocityX={{ min: -10, max: 10 }}
                  initialVelocityY={{ min: 20, max: 30 }}
                  confettiSource={{
                    x: 100,
                    y: confettiDimensions.y,
                    w: confettiDimensions.width,
                    h: confettiDimensions.height,
                  }}
                />
                <motion.div
                  initial={{
                    scale: 1,
                    opacity: 1,
                    color: 'text-secondary-semantic-success-400',
                  }}
                  animate={
                    true
                      ? {
                          scale: [1, 1.2, 1], // Scale up and then back down
                          opacity: [1, 0.5, 1], // Fade in and out
                          color: [
                            'text-secondary-semantic-success-400',
                            'gold',
                            'text-secondary-semantic-success-400',
                          ], // Color change
                          rotate: [0, 10, -10, 10, -10, 0], // Slight rotation for emphasis
                        }
                      : {}
                  }
                  transition={{
                    duration: 1, // Duration of the whole effect
                    repeat: 0,
                    ease: 'easeOut',
                  }}
                >
                  <Button
                    key={index}
                    style='poll'
                    onClick={() => {
                      setTriggerConfetti(true);
                      setSelectedOption(option);
                    }}
                    disabled={sendButtonState.answered}
                    className={getButtonClassName(option)}
                    type='button'
                    text={option}
                  />
                </motion.div>
              </>
            );
          }

          return (
            correctAnswer &&
            sendButtonState.answered &&
            option === selectedOption && (
              <motion.div
                initial={{
                  scale: 1,
                  color: 'text-secondary-semantic-success-400',
                }}
                animate={
                  selectedOption !== correctAnswer
                    ? {
                        scale: [1, 1.2, 1.1, 1.15, 1], // Scale up and down for a bounce effect
                        color: [
                          'text-secondary-semantic-success-400',
                          'red',
                          'text-secondary-semantic-success-400',
                        ], // Flash effect
                        rotate: [0, 5, -5, 5, -5, 0], // Shake effect
                      }
                    : {}
                }
                transition={{
                  duration: 0.5, // Duration of the whole effect
                  repeat: 0,
                  ease: 'easeInOut',
                }}
              >
                <Button
                  key={index}
                  style='poll'
                  onClick={() => setSelectedOption(option)}
                  disabled={sendButtonState.answered}
                  className={getButtonClassName(option)}
                  type='button'
                  text={option}
                  iconRight={
                    selectedOption === correctAnswer ? (
                      <MdCheck
                        size={24}
                        className='flex-shrink-0 text-secondary-semantic-success-400'
                      />
                    ) : (
                      <MdClear
                        size={24}
                        className='flex-shrink-0 text-secondary-semantic-error-400'
                      />
                    )
                  }
                />
              </motion.div>
            )
          );
        })}
      </form>
      {sendButtonState.answered && <SlideAnswerSubmitted />}
    </div>
  );
};
