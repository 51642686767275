import { ActivitySubtype, ActivityType } from '@domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import { RichTextTypography } from '@components/atoms';

interface RenderImageSlideProps {
  title: string;
  imageURL: string;
  type: ActivityType;
  subtype: ActivitySubtype;
  textContent?: string;
}

export const RenderImageSlide = ({
  title,
  imageURL,
  type,
  subtype,
  textContent,
}: RenderImageSlideProps) => {
  return (
    <SlideContentWrapper title={title} type={type} subtype={subtype}>
      <img
        src={imageURL!}
        alt={title}
        className='mb-8 h-56 w-full object-contain'
      />
      {textContent && <RichTextTypography description={textContent} />}
    </SlideContentWrapper>
  );
};
