import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@components/atoms';
import { useEffect, useMemo, useState } from 'react';
import {
  MdExpandLess,
  MdExpandMore,
  MdFavorite,
  MdLocalShipping,
} from 'react-icons/md';
import { TFunction } from 'i18next';
import {
  STUDENT_LATEST_RESPONSE_STORAGE_KEY,
  UserRole,
} from '@domain/constants';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { SlideAnswerSubmitted } from '../SlideAnswerSubmitted/SlideAnswerSubmitted';

interface OpenQuestionResponsesProps {
  responses: Record<string, string>[];
  role: UserRole;
  handleLike: (responseId: string) => void;
}

interface ResponseContainerProps {
  username: string;
  id: string;
  response: string;
  t: TFunction<'components/molecules/openQuestionResponse'>;
  hideFavoriteButton?: boolean;
  hideFavoriteCounter?: boolean;
  role: UserRole;
  handleLike: (responseId: string) => void;
  likes: { count: number; userIds: string[] };
}

const ResponseContainer = ({
  username,
  response,
  t,
  hideFavoriteButton,
  hideFavoriteCounter,
  role,
  handleLike,
  id,
  likes,
}: ResponseContainerProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const userId = useSelector(UserSelectors.getId);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleFavorite = (responseId: string) => {
    if (role === UserRole.STUDENT) {
      console.log(responseId);
      handleLike(responseId);
    }
  };

  useEffect(() => {
    if (likes?.userIds) {
      const isFavorite = likes.userIds.includes(userId);
      setIsFavorite(isFavorite);
    }
  }, [likes?.userIds, userId]);

  return (
    <div className='flex w-full flex-col gap-2 rounded-xl bg-secondary-surface-200 p-2.5'>
      <Typography variant='span' className='font-bold'>
        {username}
      </Typography>
      <Typography
        variant='span'
        className={`${!isExpanded ? 'line-clamp-3' : ''}`}
      >
        {response}
      </Typography>
      <div className='flex items-center justify-between'>
        {response && response?.length > 100 ? (
          <Button
            style='link'
            text={isExpanded ? t('buttons.less') : t('buttons.more')}
            onClick={toggleExpand}
            iconRight={
              isExpanded ? (
                <MdExpandLess
                  size={24}
                  className='text-secondary-buttons-100'
                />
              ) : (
                <MdExpandMore
                  size={24}
                  className='text-secondary-buttons-100'
                />
              )
            }
          />
        ) : (
          <span></span>
        )}
        {!hideFavoriteButton && (
          <div className='flex items-center gap-2'>
            {!hideFavoriteCounter && (
              <Typography variant='span' className='font-bold'>
                {likes.count}
              </Typography>
            )}
            <MdFavorite
              onClick={() => handleFavorite(id)}
              size={24}
              className={`${
                isFavorite || role === UserRole.CATECHIST
                  ? 'text-secondary-phasefour-400'
                  : 'text-secondary-buttons-500'
              }`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const OpenQuestionResponses = ({
  responses,
  role,
  handleLike,
}: OpenQuestionResponsesProps) => {
  const { t } = useTranslation('components/molecules/openQuestionResponse');
  const studentFullName = useSelector(UserSelectors.getFullName);
  const studentResponse =
    localStorage.getItem(STUDENT_LATEST_RESPONSE_STORAGE_KEY) || '';

  const filteredResponses = useMemo(() => {
    return responses.filter(response => response.response !== studentResponse);
  });

  return (
    <div className='h-full space-y-2 overflow-auto rounded-xl bg-secondary-surface-100 p-4'>
      {role === UserRole.STUDENT && studentResponse && (
        <div className='mb-4 space-y-2'>
          <Typography variant='span' className='font-bold'>
            {t('myResponse')}
          </Typography>
          <ResponseContainer
            username={studentFullName}
            response={studentResponse}
            t={t}
            hideFavoriteButton
          />
        </div>
      )}
      <Typography variant='span' className='font-bold'>
        {t('classResponses')}
      </Typography>
      {filteredResponses && filteredResponses.length >= 1 ? (
        filteredResponses.map(response => {
          return (
            <ResponseContainer
              key={response.id}
              id={response.id}
              username={response.username}
              response={response.response}
              likes={response.likes}
              t={t}
              role={role}
              hideFavoriteCounter={role === UserRole.STUDENT}
              handleLike={handleLike}
            />
          );
        })
      ) : role === UserRole.STUDENT ? (
        <SlideAnswerSubmitted className='!border-0' />
      ) : (
        <div className='flex flex-col items-center gap-2'>
          <MdLocalShipping size={24} className='text-secondary-text-200' />
          <Typography variant='span' className='font-bold !text-primary-400'>
            {t('waitingForResponses')}
          </Typography>
        </div>
      )}
    </div>
  );
};
