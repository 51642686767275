import { useParams } from 'react-router-dom';
import { Api } from '@data/api';
import { WebClientRest } from '@data/api/clients';
import { HttpStatusCode } from '@domain/api';
import {
  leaderboardAdapter,
  lessonDetailsAdapter,
  lessonPointsAdapter,
  lessonsAdapter,
} from '@data/adapters';
import { useMutation } from '@tanstack/react-query';
import { NotificationsDispatcher, trackGA4Events } from '@data/utils';
import {
  COMPLETE_CLASS_REWARD,
  COMPLETE_MODULE_REWARD,
  earnedPointsLabel,
  LESSON_IMPROVEMENT_BONUS,
  LESSON_NEW_RECORD_BONUS,
  LESSON_STATES_STR,
  PointsAnalytics,
  ToastNotificationType,
} from '@domain/constants';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loginAdapter } from '@data/adapters';
import { loginSuccess, openPointsModal, UserSelectors } from '@store/slices';

interface UpdateLessonStateParams {
  lessonId: string;
  state: LESSON_STATES_STR;
}

export const useLessons = () => {
  const { moduleId, lessonId } = useParams();
  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });
  const api = new Api(apiInstance);
  const userId = useSelector(UserSelectors.getId);

  const { t } = useTranslation([
    'common',
    'components/templates/modalSheetGlobal',
  ]);
  const dispatch = useDispatch();

  const getLessons = async () => {
    try {
      const response = await api.modules.getLessons(moduleId);

      if (response.status === HttpStatusCode.OK) {
        return lessonsAdapter(response.data);
      }

      throw Error('Error getting lessons');
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const updateAttendance = async (lessonId: string) => {
    try {
      const response = await api.lessons.updateAttendance(lessonId);

      if (response.status === HttpStatusCode.OK) {
        return response.data;
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const getLessonDetails = async (lessonId: string) => {
    try {
      const response = await api.lessons.getLessonDetails(lessonId);

      if (response.status === HttpStatusCode.OK) {
        return lessonDetailsAdapter(response.data);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const getAchievedPoints = ({
    moduleFinished,
    courseFinished,
    currentLessonPointsExceeded,
    maxLessonPointsExceeded,
    lessonScore,
  }: {
    moduleFinished: boolean;
    courseFinished: boolean;
    currentLessonPointsExceeded: boolean;
    maxLessonPointsExceeded: boolean;
    lessonScore: number;
  }) => {
    let pointsEarned = 0;
    if (moduleFinished) {
      pointsEarned += Number(sessionStorage.getItem(COMPLETE_MODULE_REWARD));
    }

    if (courseFinished) {
      pointsEarned += Number(sessionStorage.getItem(COMPLETE_CLASS_REWARD));
    }

    if (currentLessonPointsExceeded) {
      pointsEarned += Number(sessionStorage.getItem(LESSON_IMPROVEMENT_BONUS));
    }

    if (maxLessonPointsExceeded) {
      pointsEarned += Number(sessionStorage.getItem(LESSON_NEW_RECORD_BONUS));
    }

    pointsEarned += lessonScore;

    return pointsEarned;
  };

  const getStudentPoints = async () => {
    try {
      const responsePoints = await api.lessons.getLessonPoints(lessonId!);
      const responseUser = await api.users.getUser();

      if (
        responsePoints.status === HttpStatusCode.OK &&
        responseUser.status === HttpStatusCode.OK
      ) {
        const points = lessonPointsAdapter(responsePoints.data);
        const achievedPoints = getAchievedPoints({
          moduleFinished: points.moduleFinished,
          courseFinished: points.courseFinished,
          currentLessonPointsExceeded: points.currentLessonPointsExceeded,
          maxLessonPointsExceeded: points.maxLessonPointsExceeded,
          lessonScore: points.lessonScore,
        });

        trackGA4Events(
          PointsAnalytics.categories.earnedPoints,
          PointsAnalytics.actions.points,
          earnedPointsLabel(
            new Date().toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }),
            userId.toString()
          )
        );

        dispatch(loginSuccess(loginAdapter(responseUser.data)));
        dispatch(
          openPointsModal({
            isOpen: true,
            pointsEarned: achievedPoints,
            title: t('pointsGlobal.lessonFinished', {
              ns: 'components/templates/modalSheetGlobal',
            }),
          })
        );
        return points;
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
    }
  };

  const updateLessonState = async ({
    lessonId,
    state,
  }: UpdateLessonStateParams) => {
    try {
      const response = await api.lessons.updateLessonState(lessonId, state);

      if (response.status === HttpStatusCode.OK) {
        return response.data;
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const getLeaderboard = async () => {
    try {
      const response = await api.lessons.getLeaderboard(lessonId!);

      if (response.status === HttpStatusCode.OK) {
        return leaderboardAdapter(response.data);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
    }
  };

  const updateLessonStateMutation = useMutation({
    mutationFn: ({ lessonId, state }: UpdateLessonStateParams) =>
      updateLessonState({ lessonId, state }),
  });

  const updateAttendanceMutation = useMutation({
    mutationFn: ({ lessonId }: UpdateLessonStateParams) =>
      updateAttendance(lessonId!),
  });

  const navigateBackToModules = () => {};

  const gradeEndingPrayerGame = async (
    lessonId: string,
    endingPrayerGameId: string,
    points: number
  ) => {
    try {
      const response = await api.lessons.gradeEndingPrayerGame(
        lessonId,
        endingPrayerGameId,
        points
      );
      if (response.status === HttpStatusCode.OK) {
        return response.data;
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  return {
    navigateBackToModules,
    getLessons,
    updateLessonState,
    getLessonDetails,
    getStudentPoints,
    updateLessonStateMutation,
    updateAttendanceMutation,
    gradeEndingPrayerGame,
    getLeaderboard,
  };
};
