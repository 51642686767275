import { HomeTemplate } from '@components/templates';
import { InnerShadowContainer, ShopContainer } from '@components/atoms';
import { TitleWithAction } from '@components/molecules';
import { useQuery } from '@tanstack/react-query';
import { useShop } from '@hooks/index';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { TabSelector, ShopItemList } from '@components/organisms';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { trackGA4PageView } from '@data/utils';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { PAGE_NAMES } from '@domain/constants';
import { motion } from 'framer-motion';

export const PurchasedPage = () => {
  const studentPoints = useSelector(UserSelectors.getAvailablePoints);
  const {
    getShopLanding,
    navigateToShopPurchasedAvatars,
    navigateToShopPurchasedCollectibles,
  } = useShop();
  const { t } = useTranslation(
    'pages/home/shop/shopPage',
    'components/organisms/tabSelector'
  );

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['shopPurchasedLanding'],
    queryFn: () => getShopLanding('purchased'),
  });

  const shopTabs = useMemo(
    () => [
      {
        id: 'shop',
        label: t('shop', { ns: 'components/organisms/tabSelector' }),
        route: PROTECTED_ROUTES_PATHS.shop,
      },
      {
        id: 'purchased',
        label: t('purchased', { ns: 'components/organisms/tabSelector' }),
        route: PROTECTED_ROUTES_PATHS.shopPurchased,
      },
    ],
    [t]
  );

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.shopPurchased,
      title: PAGE_NAMES.shopPurchased,
    });
  }, []);

  return (
    <HomeTemplate>
      <ShopContainer
        childrenClassname='space-y-4'
        isLoading={isLoading || isPending}
      >
        <TabSelector tabs={shopTabs} />
        <motion.div
          initial={{ y: 50, opacity: 0 }} // Start below the viewport and hidden
          animate={{ y: 0, opacity: 1 }} // Move up and become visible
          transition={{ duration: 0.5, ease: 'easeOut' }} // Smooth transition
        >
          <InnerShadowContainer>
            <TitleWithAction
              label={t('avatars')}
              onClick={navigateToShopPurchasedAvatars}
              buttonText={t('buttons.viewAll')}
            />
            <ShopItemList
              items={data?.avatars}
              studentPoints={studentPoints}
              emptyStateText={t('emptyStates.avatars')}
              hidePrice
            />
          </InnerShadowContainer>
          <InnerShadowContainer>
            <TitleWithAction
              label={t('collectibles')}
              onClick={navigateToShopPurchasedCollectibles}
              buttonText={t('buttons.viewAll')}
            />
            <ShopItemList
              items={data?.collectibles}
              studentPoints={studentPoints}
              emptyStateText={t('emptyStates.collectibles')}
              hidePrice
            />
          </InnerShadowContainer>
        </motion.div>
      </ShopContainer>
    </HomeTemplate>
  );
};
