import { Typography } from '@components/atoms';
import { ActivitySubtype, ActivityType } from '@domain/constants';
import { motion } from 'framer-motion';
import AnimatedDivider from '../../atoms/AnimatedDivider/AnimatedDivider';
import {
  ClockDrawn,
  FlagDrawn,
  MaracasDrawn,
  PrayDrawn,
  SaltDrawn,
  SearchDrawn,
  VideoDrawn,
} from '@assets/img';

interface SlideTitleProps {
  title: string;
  type: ActivityType;
  subtype: ActivitySubtype;
}

export const SlideTitle = ({ title, type, subtype }: SlideTitleProps) => {
  const iconMap = {
    [ActivityType.SLIDE]: {
      [ActivitySubtype.TEXT]: MaracasDrawn,
      [ActivitySubtype.VIDEO]: VideoDrawn,
      [ActivitySubtype.IMAGE]: PrayDrawn,
    },
    [ActivityType.ACTIVITY]: {
      [ActivitySubtype.WORD_CLOUD]: SearchDrawn,
      [ActivitySubtype.PIN_IT]: FlagDrawn,
      [ActivitySubtype.POLL]: ClockDrawn,
      [ActivitySubtype.QUIZ]: ClockDrawn,
      [ActivitySubtype.OPEN_QUESTION]: SaltDrawn,
    },
  };

  const initial = { opacity: 0, scale: 0.5 };
  const animate = { opacity: 1, scale: 1 };
  const transition = {
    icon: {
      duration: 0.5,
      ease: [0, 0.71, 0.2, 1.01],
      scale: {
        type: 'spring',
        damping: 15,
        stiffness: 300,
      },
    },
    title: {
      duration: 1,
      ease: [0, 0.71, 0.2, 1.01],
      scale: {
        type: 'spring',
        damping: 15,
        stiffness: 300,
      },
      delay: 0.3,
    },
  };

  return (
    <div>
      <div className='flex items-center gap-4  [&_*]:!text-secondary-text-400'>
        <motion.div
          initial={initial}
          animate={animate}
          transition={transition['icon']}
          className='flex-shrink-0'
        >
          <img
            src={iconMap[type][subtype]}
            className='h-8 w-8 object-contain'
            alt='Slide icon'
          />
        </motion.div>
        <motion.div
          initial={initial}
          animate={animate}
          transition={transition['title']}
          className='flex-grow'
        >
          <Typography variant='h1'>{title}</Typography>
        </motion.div>
      </div>

      <AnimatedDivider containerStyles='-my-2' animationDelay={0.5} />
    </div>
  );
};
