import { HomeTemplate } from '@components/templates';
import { InnerShadowContainer, ShopContainer } from '@components/atoms';
import { TitleWithAction } from '@components/molecules';
import { useQuery } from '@tanstack/react-query';
import { useShop } from '@hooks/index';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { TabSelector } from '@components/organisms';
import { useTranslation } from 'react-i18next';
import { ShopItemList } from '@components/organisms';
import { useEffect, useMemo, useState } from 'react';
import { trackGA4PageView } from '@data/utils';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { PAGE_NAMES } from '@domain/constants';
import { motion } from 'framer-motion';

export const ShopPage = () => {
  const studentAvailablePoints = useSelector(UserSelectors.getAvailablePoints);
  const {
    getShopLanding,
    navigateToShopAvatars,
    navigateToShopCollectibles,
    getUserPoints,
  } = useShop();
  const { t } = useTranslation([
    'pages/home/shop/shopPage',
    'components/organisms/tabSelector',
  ]);

  const shopTabs = useMemo(
    () => [
      {
        id: 'shop',
        label: t('shop', { ns: 'components/organisms/tabSelector' }),
        route: PROTECTED_ROUTES_PATHS.shop,
      },
      {
        id: 'purchased',
        label: t('purchased', { ns: 'components/organisms/tabSelector' }),
        route: PROTECTED_ROUTES_PATHS.shopPurchased,
      },
    ],
    [t]
  );

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['shopLanding'],
    queryFn: () => getShopLanding('available'),
  });

  const getUserPointsQuery = useQuery({
    queryKey: ['userPoints'],
    queryFn: getUserPoints,
  });

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.shop,
      title: PAGE_NAMES.shop,
    });
  }, []);

  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHasRendered(true);
    }, 500); // Delay match the duration of the initial animations

    return () => clearTimeout(timer);
  }, []);

  return (
    <HomeTemplate>
      <ShopContainer
        childrenClassname='space-y-4'
        isLoading={isLoading || isPending}
      >
        <TabSelector tabs={shopTabs} />
        <motion.div
          initial={{ y: 50, opacity: 0 }} // Start below the viewport and hidden
          animate={{ y: 0, opacity: 1 }} // Move up and become visible
          transition={{ duration: 0.3, ease: 'easeOut' }} // Smooth transition
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.9 }}
        >
          <InnerShadowContainer onClick={navigateToShopAvatars}>
            <TitleWithAction
              label={t('avatars')}
              onClick={navigateToShopAvatars}
              buttonText={t('buttons.viewAll')}
            />
            <ShopItemList
              items={data?.avatars}
              studentPoints={studentAvailablePoints}
            />
          </InnerShadowContainer>
        </motion.div>

        <motion.div
          initial={{ y: 50, opacity: 0 }} // Start below the viewport and hidden
          animate={{ y: 0, opacity: 1 }} // Move up and become visible
          transition={{
            duration: 0.3,
            ease: 'easeOut',
            delay: hasRendered ? 0 : 0.2,
          }} // Smooth transition
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.9 }}
        >
          <InnerShadowContainer onClick={navigateToShopCollectibles}>
            <TitleWithAction
              label={t('collectibles')}
              onClick={navigateToShopCollectibles}
              buttonText={t('buttons.viewAll')}
            />
            <ShopItemList
              items={data?.collectibles}
              studentPoints={studentAvailablePoints}
            />
          </InnerShadowContainer>
        </motion.div>
      </ShopContainer>
    </HomeTemplate>
  );
};
