import { ModalSheet, LinkButton } from '@components/molecules';
import { useSelector, useDispatch } from 'react-redux';
import { ActivitySelectors, handleTextSlideLink } from '@store/slices';

interface ModalSheetLinkEmbedProps {
  link: string;
}

export const ModalSheetLinkEmbed = ({ link }: ModalSheetLinkEmbedProps) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(ActivitySelectors.getTextSlideLink);

  return (
    <>
      <LinkButton
        text={link}
        onClick={() => dispatch(handleTextSlideLink({ isOpen: true }))}
      />

      <ModalSheet
        isOpen={isOpen}
        onClose={() => dispatch(handleTextSlideLink({ isOpen: false }))}
      >
        <iframe
          src={link}
          title='Embedded Content'
          width='100%'
          className='h-[70vh]'
          allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      </ModalSheet>
    </>
  );
};
