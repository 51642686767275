import { Input, Textarea } from '@components/atoms';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SlideAnswerSubmitted } from '../SlideAnswerSubmitted/SlideAnswerSubmitted';
import {
  everythingPattern,
  oneWordPattern,
  trackGA4Events,
  validateAppropiateLanguage,
} from '@data/utils';
import {
  ActivityAnalytics,
  activityDoneLabel,
  ActivitySubtype,
  InputType,
  STUDENT_LATEST_RESPONSE_STORAGE_KEY,
} from '@domain/constants';
import { SendButtonContext } from '@context/index';
import { UserSelectors } from '@store/slices';
import { useSelector } from 'react-redux';

interface SlideTextInputProps {
  questionText?: string;
  onSubmit: (answer: string) => void;
  activitySubtype?: ActivitySubtype;
  isTextarea?: boolean;
}

type Inputs = {
  response: string;
};

export const SlideTextInput = ({
  onSubmit,
  activitySubtype,
  isTextarea,
}: SlideTextInputProps) => {
  const { t } = useTranslation('components/molecules/slideTextInput');

  const { setSendButtonState, sendButtonState } = useContext(SendButtonContext);
  const userId = useSelector(UserSelectors.getId);

  const handleSubmitResponse = async (data: Inputs) => {
    try {
      setSendButtonState(state => ({
        ...state,
        loading: true,
      }));
      await onSubmit(data.response);
      setSendButtonState({
        loading: false,
        disabled: true,
        answered: true,
      });

      localStorage.setItem(STUDENT_LATEST_RESPONSE_STORAGE_KEY, data.response);

      trackGA4Events(
        ActivityAnalytics.categories.activityDone,
        ActivityAnalytics.actions.activity,
        activityDoneLabel(
          new Date().toLocaleDateString('en-us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
          userId.toString(),
          activitySubtype?.toString() || ''
        )
      );

      reset();
    } catch (error) {
      console.log(error);
      setSendButtonState({
        loading: false,
        disabled: false,
        answered: false,
      });
      throw error;
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm<Inputs>({
    defaultValues: {
      response: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    setSendButtonState(state => ({
      ...state,
      disabled: !isValid,
    }));
  }, [isValid, setSendButtonState]);

  return (
    <div>
      <form id='slide-form' onSubmit={handleSubmit(handleSubmitResponse)}>
        {isTextarea ? (
          <Textarea
            control={control}
            label=''
            name='response'
            disabled={sendButtonState.answered}
            className={
              'h-52 max-h-52 min-h-12 resize-none overflow-auto leading-[18px]'
            }
            rules={{
              ...validateAppropiateLanguage(),
              required: {
                value: true,
                message: t('errors.required'),
              },
            }}
            placeholder='Type your answer here'
          />
        ) : (
          <Input
            control={control}
            label=''
            name='response'
            type={InputType.text}
            disabled={sendButtonState.answered}
            rules={{
              ...validateAppropiateLanguage(),
              required: {
                value: true,
                message: t('errors.required'),
              },
              pattern: {
                value:
                  activitySubtype === ActivitySubtype.WORD_CLOUD
                    ? oneWordPattern
                    : everythingPattern,
                message: t('errors.oneWord'),
              },
            }}
          />
        )}
      </form>

      {sendButtonState.answered && <SlideAnswerSubmitted />}
    </div>
  );
};
