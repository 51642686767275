import { AnimatedButton, BottomCard, Button } from '@components/atoms';
import { SlideSubtitle, SlideSubtitleProps } from '@components/molecules';
import {
  ProgressStepper,
  ProgressStepperProps,
  SlideControls,
  type SlideControlsProps,
} from '@components/organisms';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices/userSlice';
import { ActivitySubtype, ActivityType, UserRole } from '@domain/constants';
import { useTranslation } from 'react-i18next';
import { ReactNode, useState } from 'react';
import { SendButtonContext } from '@context/index';

interface SlideProps
  extends SlideControlsProps,
    SlideSubtitleProps,
    Omit<ProgressStepperProps, 'leftComponent'> {
  children: ReactNode;
}

export const Slide = ({
  nextButtonText,
  children,
  onClickNext,
  subtype,
  type,
  currentStep,
  maxSteps,
  onShowResults,
  showResults,
}: SlideProps) => {
  const role = useSelector(UserSelectors.getRole);
  const [sendButtonState, setSendButtonState] = useState({
    loading: false,
    disabled: false,
    answered: false,
    hide: false,
  });

  const { t } = useTranslation('components/templates/slide');

  return (
    <div className='flex h-dvh flex-col !bg-secondary-surface-100 pt-[100px]'>
      <ProgressStepper
        stepperStyles='!text-secondary-text-300'
        containerStyles='px-6 py-8'
        currentStep={currentStep}
        maxSteps={maxSteps}
        leftComponent={<SlideSubtitle type={type} subtype={subtype} />}
      />
      <BottomCard containerStyles='bg-secondary-surface-200 shadow-inner justify-between !p-6 '>
        <div className='flex flex-1 flex-col justify-between'>
          <SendButtonContext.Provider
            value={{
              sendButtonState,
              setSendButtonState,
            }}
          >
            <div className='mb-12 h-full overflow-auto'>{children}</div>
            <div className='fixed bottom-4 left-0 right-0 z-10 mt-4 flex w-full items-center justify-center'>
              {role === UserRole.STUDENT &&
              type === ActivityType.ACTIVITY &&
              !showResults &&
              !sendButtonState.hide ? (
                <AnimatedButton
                  form='slide-form'
                  type='submit'
                  className='!w-[90%]'
                  disabled={sendButtonState.disabled || sendButtonState.loading}
                  loading={sendButtonState.loading}
                  style='primary'
                  text={t('send')}
                />
              ) : null}
              {role === UserRole.CATECHIST &&
              subtype !== ActivitySubtype.OPEN_QUESTION ? (
                <SlideControls
                  onClickNext={onClickNext}
                  nextButtonText={nextButtonText}
                  onShowResults={onShowResults}
                  showResults={showResults}
                />
              ) : null}
            </div>
          </SendButtonContext.Provider>
        </div>
      </BottomCard>
    </div>
  );
};
