import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  BorderContainer,
  RichTextTypography,
  CustomDatePicker,
  CustomTimePicker,
} from '@components/atoms';
import { Challenge } from '@assets/img';
import { DueDate } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { Reto, UserRole } from '@domain/constants';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { useDueDate } from '@hooks/useDueDate';

interface ChallengeContentProps {
  challenge: Reto;
}

export const ChallengeContent = ({ challenge }: ChallengeContentProps) => {
  const { t } = useTranslation('components/organisms/challengeContent');
  const role = useSelector(UserSelectors.getRole);
  const { dueDate, isDateSet, sendDueDateUpdate } = useDueDate({
    channel: `due-date-channel-${challenge.id}`,
    slideId: challenge.id,
  });
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [minDate, setMinDate] = useState<Date>(new Date());

  useEffect(() => {
    const oneWeekFromNow = new Date();
    oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
    setMinDate(oneWeekFromNow);
  }, []);

  const initializeDueDate = useCallback(() => {
    if (challenge.dueDate) {
      const initialDueDate = new Date(challenge.dueDate);
      if (!isNaN(initialDueDate.getTime()) && initialDueDate >= minDate) {
        setSelectedDate(initialDueDate);
        setSelectedTime(initialDueDate);
        sendDueDateUpdate(initialDueDate);
        return;
      }
    }
    setSelectedDate(minDate);
    setSelectedTime(minDate);
  }, [challenge.dueDate, sendDueDateUpdate, minDate]);

  useEffect(() => {
    initializeDueDate();
  }, [initializeDueDate]);

  const updateDueDate = useCallback(() => {
    if (selectedDate && selectedTime) {
      const newDueDate = new Date(selectedDate);
      newDueDate.setHours(
        selectedTime.getHours(),
        selectedTime.getMinutes(),
        0,
        0
      );
      sendDueDateUpdate(newDueDate);
    }
  }, [selectedDate, selectedTime, sendDueDateUpdate]);

  useEffect(() => {
    updateDueDate();
  }, [selectedDate, selectedTime, updateDueDate]);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time: Date) => {
    setSelectedTime(time);
  };

  return (
    <div className='flex h-full w-full flex-col items-center justify-between'>
      <div className='grid place-items-center gap-2'>
        <Typography
          variant='h3'
          className='leading-11 text-4xl font-medium !text-primary-400'
        >
          {t('reto')}
        </Typography>
        <Typography
          variant='span'
          className='text-base font-medium leading-5 !text-primary-400'
        >
          {t(`types.${challenge.type}`)}
        </Typography>
      </div>

      <BorderContainer containerStyles='flex p-4 !w-fit items-center border-primary-200 justify-center border !border-primary-200'>
        <Typography
          variant='span'
          className='text-lg font-bold !text-primary-400'
        >
          {t('winPoints', { points: challenge.pointsAwarded })}
        </Typography>
      </BorderContainer>

      <img src={Challenge} className='h-auto w-64'></img>

      <div className='flex flex-col gap-4'>
        <Typography
          variant='span'
          className='text-lg font-medium leading-[1.375rem]'
        >
          {challenge.title}
        </Typography>

        {challenge.description && (
          <RichTextTypography description={challenge.description} />
        )}
      </div>

      {role === UserRole.CATECHIST ? (
        <div className='flex !w-full flex-col items-center gap-4 py-4'>
          <div className='flex items-center'>
            <Typography
              variant='body1'
              className='p-1 text-center text-lg font-semibold leading-[1.375rem] !text-primary-400'
            >
              {t('dueDate')}
            </Typography>
          </div>
          <div className='w-full'>
            <Typography
              variant='span'
              className='text-left text-sm leading-[1.125rem] !text-primary-400'
            >
              {t('date')}
            </Typography>
            <CustomDatePicker
              selectedDate={selectedDate}
              onChange={handleDateChange}
              minDate={minDate}
            />
          </div>
          <div className='w-full'>
            <Typography
              variant='span'
              className='text-left text-sm leading-[1.125rem] !text-primary-400'
            >
              {t('time')}
            </Typography>
            <CustomTimePicker
              selectedTime={selectedTime}
              onChange={handleTimeChange}
            />
          </div>
        </div>
      ) : (
        <div className='flex min-h-16 !w-full flex-shrink-0 items-center py-4'>
          <DueDate date={isDateSet ? dueDate : null} />
        </div>
      )}
    </div>
  );
};
