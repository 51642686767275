export const IS_SPLASH_SCREEN_STORAGE_KEY = 'isSplashScreen';
export const AUTH_TOKEN_STORAGE_KEY = 'authToken';

export const COURSE_NAME_STORAGE_KEY = 'courseName';
export const COURSE_PUBLIC_UID_STORAGE_KEY = 'coursePublicUid';

export const LESSON_BACKGROUND_COLOR_STORAGE_KEY = 'lessonBackgroundColor';
export const LESSON_TEXT_COLOR_STORAGE_KEY = 'lessonTextColor';
export const LESSON_TITLE_STORAGE_KEY = 'lessonTitle';
export const LESSON_DESCRIPTION_STORAGE_KEY = 'lessonDescription';

export const RETO_TYPE_STORAGE_KEY = 'retoType';
export const RETO_TITLE_STORAGE_KEY = 'retoTitle';
export const RETO_POINTS_STORAGE_KEY = 'retoPoints';

export const DAILY_PRAYER_GAME_STORAGE_KEY = 'dailyPrayerGame';
export const LAST_PLAYED_DAILY_PRAYER_GAME_STORAGE_KEY =
  'lastPlayedDailyPrayerGame';

export const STUDENT_LATEST_RESPONSE_STORAGE_KEY = 'studentLatestResponse';

export const JOINED_LESSON_STORAGE_KEY = 'joined';
export const LESSON_STARTED_STORAGE_KEY = 'lessonStarted';
