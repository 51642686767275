import { Button, Typography, Lottie } from '@components/atoms';
import { HeartBeatingLottie } from '@assets/lotties';
import { QuestionHand } from '@assets/img';
import { useTranslation } from 'react-i18next';
import { UserSelectors } from '@store/slices';
import { useSelector } from 'react-redux';
import { UserRole } from '@domain/constants';

interface FavoriteAnswerProps {
  favoriteResponses: Record<string, string>[] | undefined;
  onClickNext: () => void;
}

export const FavoriteAnswer = ({
  favoriteResponses,
  onClickNext,
}: FavoriteAnswerProps) => {
  const { t } = useTranslation('components/molecules/favoriteAnswer');
  const userRole = useSelector(UserSelectors.getRole);

  return (
    <div className='flex flex-col items-center'>
      {favoriteResponses && favoriteResponses.length > 0 ? (
        <>
          <Lottie animationData={HeartBeatingLottie} className='-mt-12 w-44' />
          <div className='carousel w-full gap-4'>
            {favoriteResponses.map((response, index) => (
              <div
                className='carousel-item flex w-full flex-col rounded-xl border border-primary-200 bg-secondary-surface-200 shadow-inner'
                id={`favoriteResponse${index}`}
                key={index}
              >
                <div className='space-y-2 px-2 py-4'>
                  <Typography variant='p' className='font-bold'>
                    {response.username}
                  </Typography>
                  <Typography variant='p'>{response.response}</Typography>
                </div>
              </div>
            ))}
          </div>
          {favoriteResponses.length > 1 && (
            <div className='mt-4 flex w-full justify-center gap-2 pt-1'>
              {favoriteResponses.map((_, index) => (
                <a
                  key={index}
                  href={`#favoriteResponse${index}`}
                  className='btn btn-xs'
                >
                  {index + 1}
                </a>
              ))}
            </div>
          )}
        </>
      ) : (
        <div className='mx-8 flex flex-col items-center gap-2 [&_*]:text-center'>
          <img className='mb-4 w-44' src={QuestionHand}></img>
          <Typography variant='p' className='font-bold'>
            {t('emptyState.title')}
          </Typography>
          <Typography variant='p'>{t('emptyState.description')}</Typography>
        </div>
      )}

      <div className='fixed bottom-4 left-0 right-0 z-10 mt-4 flex w-full items-center justify-center'>
        {userRole === UserRole.CATECHIST && (
          <Button
            style='primary'
            className='!w-[90%]'
            text='Next'
            onClick={onClickNext}
          />
        )}
      </div>
    </div>
  );
};
