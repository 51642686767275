import ImageMarker, { Marker } from 'react-image-marker';
import { PinMarker } from '@components/atoms';
import { useContext, useEffect, useState } from 'react';
import { SlideAnswerSubmitted } from '@components/molecules';
import { UserSelectors } from '@store/slices/userSlice';
import { useSelector } from 'react-redux';
import {
  ActivityAnalytics,
  activityDoneLabel,
  ActivitySubtype,
  UserRole,
} from '@domain/constants';
import { SendButtonContext } from '@context/index';
import { AnimatePresence, motion } from 'framer-motion';
import './FadeMask.css';
import { trackGA4Events } from '@data/utils';

interface PinActivityProps {
  slideId: number;
  image: string;
  markers: Marker[];
  setMarkers: (markers: Marker) => void;
  showResults: boolean;
}

export const PinActivity = ({
  image,
  markers,
  setMarkers,
  slideId,
  showResults,
}: PinActivityProps) => {
  const [localMarker, setLocalMarker] = useState<Marker>();
  const role = useSelector(UserSelectors.getRole);
  const userId = useSelector(UserSelectors.getId);

  const { setSendButtonState, sendButtonState } = useContext(SendButtonContext);

  const handleSubmitMarker = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      setSendButtonState(state => ({
        ...state,
        loading: true,
      }));
      e.preventDefault();
      setMarkers(localMarker);

      trackGA4Events(
        ActivityAnalytics.categories.activityDone,
        ActivityAnalytics.actions.activity,
        activityDoneLabel(
          new Date().toLocaleDateString('en-us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
          userId.toString(),
          ActivitySubtype.PIN_IT
        )
      );
      setSendButtonState({
        loading: false,
        disabled: true,
        answered: true,
      });
    } catch (error) {
      console.log(error);
      setSendButtonState({
        loading: false,
        disabled: false,
        answered: false,
      });
      throw error;
    }
  };

  useEffect(() => {
    setSendButtonState(state => ({
      ...state,
      disabled: !localMarker,
    }));
  }, [localMarker, setSendButtonState]);

  useEffect(() => {
    setLocalMarker(undefined);
  }, [slideId]);

  return (
    <AnimatePresence mode='wait'>
      <div className='flex flex-col gap-8'>
        {sendButtonState.answered && !showResults && <SlideAnswerSubmitted />}
        {showResults && role === UserRole.STUDENT ? (
          <div>
            <ImageMarker
              src={image}
              markerComponent={PinMarker}
              markers={markers}
              onAddMarker={() => {}}
            />
          </div>
        ) : (
          <AnimatePresence mode='wait'>
            <motion.div
              initial={{ y: '-100vh', rotate: -30, opacity: 0 }}
              animate={
                sendButtonState.answered
                  ? { rotate: -15, scale: 0.8 }
                  : { y: 0, rotate: 0, opacity: 1 }
              }
              transition={
                sendButtonState.answered
                  ? {
                      type: 'spring',
                      stiffness: 80,
                      damping: 20,
                      duration: 0.6,
                    }
                  : {
                      type: 'spring',
                      stiffness: 200,
                      damping: 12,
                      mass: 0.5,
                      delay: 1,
                      duration: 1,
                    }
              }
            >
              <ImageMarker
                src={image}
                markerComponent={PinMarker}
                markers={
                  (role === UserRole.CATECHIST && markers) ||
                  sendButtonState.answered
                    ? markers
                    : localMarker
                      ? [localMarker]
                      : []
                }
                onAddMarker={marker => {
                  if (role === UserRole.CATECHIST) return;
                  if (sendButtonState.answered) return;

                  setLocalMarker(marker);
                }}
              />
              <form onSubmit={handleSubmitMarker} id='slide-form'></form>
            </motion.div>
          </AnimatePresence>
        )}
      </div>
    </AnimatePresence>
  );
};
