import {
  PUBLIC_ROUTES_PATHS,
  PROTECTED_ROUTES_PATHS,
} from '@navigation/routes/RoutesPaths';
import {
  LoginPage,
  ActivityPage,
  SignupController,
  WelcomeBackPage,
  SplashPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  HomePage,
  ShopPage,
  LessonsPage,
  RetosPage,
  MyProgressPage,
  ModulesPage,
  NotFoundPage,
  DetailsPage,
  EditNamePage,
  ChangePasswordPage,
  SelectAvatarPage,
  ProfilePage,
  EditParentsPage,
  StudentsPage,
  PrayerGamePage,
  PurchasedAvatarsPage,
  PurchasedPage,
  ShopAvatarsPage,
  ShopCollectiblesPage,
  PurchasedCollectiblesPage,
  RetosAnswerPage,
  RetosResponsesPage,
  RetoResponseDetailsPage,
  LessonSummaryPage,
  SplashRedirect,
  GradedRetosPage,
  LeaderboardPage,
  LessonVideosPage,
} from '@pages/index';
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import ProtectedRoutes from '@navigation/routes/ProtectedRoutes';
import { useAuth } from '@hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { initializeGA4 } from '@data/utils/googleAnalytics';
import { UserRole } from '@domain/constants';
import { ProtectedRole } from '@navigation/routes/ProtectedRole';

export const Navigation = () => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation('pages/common/notFoundPage/notFoundPage');

  const MAIN_ROUTES: RouteObject[] = useMemo(
    () => [
      {
        element: <SplashRedirect />,
        children: [
          {
            path: PUBLIC_ROUTES_PATHS.splash,
            element: isAuthenticated ? <WelcomeBackPage /> : <SplashPage />,
          },
          {
            path: PUBLIC_ROUTES_PATHS.login,
            element: <LoginPage />,
          },
          {
            path: PUBLIC_ROUTES_PATHS.forgotPassword,
            element: <ForgotPasswordPage />,
          },
          {
            path: PUBLIC_ROUTES_PATHS.resetPassword,
            element: <ResetPasswordPage />,
          },
        ],
      },
      {
        path: PUBLIC_ROUTES_PATHS.signup,
        element: <SignupController />,
      },
      {
        path: '*',
        element: (
          <NotFoundPage
            descriptionOne={t('descriptions.notFound')}
            descriptionTwo={t('goBack')}
          />
        ),
      },
      {
        element: <ProtectedRoutes />,
        errorElement: (
          <NotFoundPage
            descriptionOne={t('descriptions.general')}
            descriptionTwo={t('goBack')}
          />
        ),
        children: [
          {
            path: PROTECTED_ROUTES_PATHS.home,
            element: <HomePage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.modules,
            element: <ModulesPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.retos,
            element: <RetosPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.gradedRetos,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <GradedRetosPage />
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.myProgress,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <MyProgressPage />
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.activity,
            element: <ActivityPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.leaderboard,
            element: (
              <ProtectedRole allowedRoles={[UserRole.CATECHIST]}>
                <LeaderboardPage />
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.videos,
            element: (
              <ProtectedRole allowedRoles={[UserRole.CATECHIST]}>
                <LessonVideosPage />
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.shop,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <ShopPage />
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.shopAvatars,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <ShopAvatarsPage />,
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.shopCollectibles,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <ShopCollectiblesPage />,
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.shopPurchased,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <PurchasedPage />
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.shopPurchasedAvatars,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <PurchasedAvatarsPage />,
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.shopPurchasedCollectibles,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <PurchasedCollectiblesPage />,
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.profile,
            element: <ProfilePage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.lessons,
            element: <LessonsPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.profileDetails,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <DetailsPage />,
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.profileDetailsEditName,

            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <EditNamePage />
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.changePassword,
            element: <ChangePasswordPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.profileAvatar,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <SelectAvatarPage />
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.profileDetailsEditParents,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <EditParentsPage />
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.students,
            element: (
              <ProtectedRole allowedRoles={[UserRole.CATECHIST]}>
                <StudentsPage />
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.prayerGame,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <PrayerGamePage />
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.retosAnswer,
            element: (
              <ProtectedRole allowedRoles={[UserRole.STUDENT]}>
                <RetosAnswerPage />,
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.retosResponses,
            element: (
              <ProtectedRole allowedRoles={[UserRole.CATECHIST]}>
                <RetosResponsesPage />
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.retoResponseDetails,
            element: (
              <ProtectedRole allowedRoles={[UserRole.CATECHIST]}>
                <RetoResponseDetailsPage />,
              </ProtectedRole>
            ),
          },
          {
            path: PROTECTED_ROUTES_PATHS.lessonSummary,
            element: (
              <ProtectedRole allowedRoles={[UserRole.CATECHIST]}>
                <LessonSummaryPage />,
              </ProtectedRole>
            ),
          },
        ],
      },
    ],
    [isAuthenticated, t]
  );

  const router = useMemo(
    () => createBrowserRouter([...MAIN_ROUTES]),
    [MAIN_ROUTES]
  );

  useEffect(() => {
    initializeGA4();
  }, []);

  return <RouterProvider router={router} />;
};
