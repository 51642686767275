import {
  ChallengeSlide,
  Slide,
  WaitingRoomSlide,
  EndingPrayerSlide,
  ModalSheetLessonNavigation,
} from '@components/templates';
import { SlideContent } from '@components/templates';
import {
  useAchievements,
  useCloseLesson,
  useLessons,
  useShowResults,
  useSlideControls,
} from '@hooks/index';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import {
  Activity,
  ActivityType,
  LESSON_STATES_STR,
  lessonCompletedLabel,
  LessonsAnalytics,
  lessonStartLabel,
  UserRole,
  JOINED_LESSON_STORAGE_KEY,
  LESSON_STARTED_STORAGE_KEY,
} from '@domain/constants';
import {
  closeModalCloseLesson,
  handleBackButton,
  handleModalLessonNavigationShowing,
  LessonSelectors,
  ModalCloseLessonSelectors,
  openInfoModal,
  removeShowingModalCloseLesson,
  setExitingModalCloseLesson,
  setWaitingRoom,
  UserSelectors,
} from '@store/slices';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { usePubNub } from 'pubnub-react';
import { Taco } from '@assets/img';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ModalCloseLesson } from '@components/organisms';
import { trackGA4Events } from '@data/utils';

interface ActivityControllerProps {
  activity: Activity;
}

export const ActivityController = ({ activity }: ActivityControllerProps) => {
  const { step, handleSlide } = useSlideControls({
    channel: activity.publicUid,
  });
  const { closeLessonMutation } = useCloseLesson({
    channel: activity.publicUid,
  });

  const { showResults, sendShowResults } = useShowResults({
    channel: activity.publicUid,
  });

  const isOpen = useSelector(ModalCloseLessonSelectors.getIsOpen);
  const isWaitingRoom = useSelector(LessonSelectors.getIsWaitingRoom);
  const userId = useSelector(UserSelectors.getId);

  const pubnub = usePubNub();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = useSelector(UserSelectors.getRole);
  const { classId, lessonId, moduleId } = useParams();

  const { updateLessonState, updateAttendanceMutation, getStudentPoints } =
    useLessons();

  const { getAchievedBadges } = useAchievements();

  const updateLessonStateMutation = useMutation({
    mutationFn: ({ lessonId, state }) => updateLessonState({ lessonId, state }),
  });

  const { refetch: refetchStudentPoints } = useQuery({
    queryKey: ['getStudentPoints'],
    queryFn: getStudentPoints,
    enabled: false,
    retry: false,
  });

  const { refetch: refetchAchievedBadges } = useQuery({
    queryKey: ['getAchievedBadges'],
    queryFn: getAchievedBadges,
    enabled: false,
    retry: false,
  });

  const { t } = useTranslation([
    'components/organisms/slideControls',
    'components/templates/modalSheetGlobal',
  ]);

  const contentLength = activity.content.length;
  const challengesLength = activity.retos.length;
  const endingPrayerGameLength = activity.endingPrayerGame ? 1 : 0;
  const totalSlides = contentLength + challengesLength + endingPrayerGameLength;

  const checkAchievedBadges = async () => {
    await refetchAchievedBadges();
  };

  const finishLesson = useCallback(async () => {
    try {
      dispatch(setWaitingRoom(true));
      await dispatch(setExitingModalCloseLesson(true));

      localStorage.removeItem(
        `${JOINED_LESSON_STORAGE_KEY}_${activity.publicUid}`
      );
      localStorage.removeItem(
        `${LESSON_STARTED_STORAGE_KEY}_${activity.publicUid}`
      );

      if (userRole === UserRole.CATECHIST) {
        await updateLessonStateMutation.mutateAsync({
          lessonId: activity.publicUid,
          state: LESSON_STATES_STR.complete,
        });
      }

      if (userRole === UserRole.STUDENT) {
        dispatch(
          openInfoModal({
            isOpen: true,
            image: Taco,
            title: t('lessonCompleted.title', {
              ns: 'components/templates/modalSheetGlobal',
            }),
            description: t('lessonCompleted.description', {
              ns: 'components/templates/modalSheetGlobal',
            }),
            backgroundColor: 'orange',
          })
        );

        trackGA4Events(
          LessonsAnalytics.categories.lessonCompleted,
          LessonsAnalytics.actions.lesson,
          lessonCompletedLabel(
            new Date().toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }),
            userId.toString(),
            activity.publicUid.toString()
          )
        );

        refetchStudentPoints();
        checkAchievedBadges();

        navigate(PROTECTED_ROUTES_PATHS.home);
      }

      pubnub.unsubscribeAll();
      dispatch(setExitingModalCloseLesson(false));

      if (userRole === UserRole.CATECHIST) {
        navigate(
          PROTECTED_ROUTES_PATHS.leaderboard
            .replace(':classId', classId!)
            .replace(':moduleId', moduleId!)
            .replace(':lessonId', lessonId!)
        );
      } else {
        navigate(PROTECTED_ROUTES_PATHS.home);
      }
    } catch (error) {
      console.error('Error finishing lesson:', error);
    }
  }, [classId, moduleId, lessonId]);

  useEffect(() => {
    if (step !== -1) {
      dispatch(setWaitingRoom(false));
    }

    if (step >= totalSlides) {
      finishLesson();
    }
  }, [dispatch, finishLesson, step, totalSlides]);

  useEffect(() => {
    const updateAttendance = async () => {
      try {
        await updateAttendanceMutation.mutateAsync({
          lessonId: activity.publicUid,
        });
      } catch (error) {
        return;
      }
    };

    if (userRole === UserRole.STUDENT) {
      updateAttendance();
    }
  }, [activity.publicUid, userRole]);

  const handleExitlesson = async () => {
    await closeLessonMutation.mutateAsync({
      state: LESSON_STATES_STR.stop,
    });

    dispatch(closeModalCloseLesson());
  };

  useEffect(() => {
    if (!isWaitingRoom && step >= 1) {
      trackGA4Events(
        LessonsAnalytics.categories.lessonStart,
        LessonsAnalytics.actions.lesson,
        lessonStartLabel(
          new Date().toLocaleDateString('en-us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
          userId.toString(),
          activity.publicUid.toString()
        )
      );
    }
  }, [isWaitingRoom]);

  const renderSlide = (activity: Activity) => {
    const content = activity.content;
    const challenges = activity.retos;

    if (step === -1) {
      return (
        <>
          <WaitingRoomSlide
            channelName={activity.publicUid}
            onClickNext={() => handleSlide(step + 1)}
            lessonId={activity.publicUid}
          />
          <ModalCloseLesson
            isOpen={isOpen}
            clickExitLesson={handleExitlesson}
          />
        </>
      );
    }

    if (step < content.length) {
      if (userRole === UserRole.STUDENT) {
        dispatch(handleBackButton(false));
      }

      if (userRole === UserRole.CATECHIST) {
        dispatch(handleModalLessonNavigationShowing(true));
      }

      const currentContent = content[step];
      if (!currentContent) {
        return null;
      }

      const type = currentContent.type;
      const subtype = currentContent.subtype;
      const isShowActivityAble = type === ActivityType.ACTIVITY;

      return (
        <>
          <Slide
            currentStep={step + 1}
            maxSteps={content.length}
            type={type}
            subtype={subtype}
            nextButtonText={t('nextActivity')}
            onClickNext={() => handleSlide(step + 1)}
            onShowResults={isShowActivityAble ? sendShowResults : undefined}
            showResults={showResults}
          >
            <SlideContent
              slide={currentContent}
              channel={activity.publicUid}
              showResults={showResults}
              onClickNext={() => handleSlide(step + 1)}
            />
          </Slide>
          <ModalCloseLesson
            isOpen={isOpen}
            clickExitLesson={handleExitlesson}
          />
          <ModalSheetLessonNavigation content={content} currentStep={step} />
        </>
      );
    }

    if (step < content.length + challenges.length) {
      dispatch(removeShowingModalCloseLesson());
      dispatch(handleModalLessonNavigationShowing(false));
      return (
        <ChallengeSlide
          onClickNext={() => handleSlide(step + 1)}
          challenge={challenges[step - content.length]}
        />
      );
    }

    if (step === content.length + challengesLength) {
      if (
        activity.endingPrayerGame &&
        activity.endingPrayerGame.prayerText &&
        activity.endingPrayerGame.correctAnswers &&
        activity.endingPrayerGame.blankPositions
      ) {
        dispatch(handleModalLessonNavigationShowing(false));
        dispatch(removeShowingModalCloseLesson());

        return (
          <EndingPrayerSlide
            onClickNext={() => {
              handleSlide(step + 1);
            }}
            endingPrayerGame={activity.endingPrayerGame}
            lessonId={activity.publicUid}
            onComplete={(filledPrayer, points, allCorrect) => {}}
            nextButtonText={t('Next')}
          />
        );
      } else {
        handleSlide(step + 1);
        return null;
      }
    }

    return null;
  };

  return renderSlide(activity);
};
