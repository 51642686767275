import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface ActivityState {
  textSlideLink: TextSlideLinkProps;
}

interface TextSlideLinkProps {
  isOpen: boolean;
}

const initialState: ActivityState = {
  textSlideLink: {
    isOpen: false,
  },
};

const activity = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    handleTextSlideLink: (state, action: PayloadAction<TextSlideLinkProps>) => {
      state.textSlideLink = action.payload;
    },
  },
});

export const ActivitySelectors = {
  getTextSlideLink: (state: RootState) => state.activity.textSlideLink,
};

export const { handleTextSlideLink } = activity.actions;

export const ActivityReducer = activity.reducer;
