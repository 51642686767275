import { ModalSheet, NavigationCard } from '@components/molecules';
import { useDispatch, useSelector } from 'react-redux';
import {
  ModalLessonNavigationSelectors,
  handleModalLessonNavigation,
} from '@store/slices';

interface ModalSheetLessonNavigationProps {
  content: Record<string, string | number>[];
  currentStep: number;
}

export const ModalSheetLessonNavigation = ({
  content,
  currentStep,
}: ModalSheetLessonNavigationProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(ModalLessonNavigationSelectors.getIsOpen);

  const handleClose = () => {
    dispatch(handleModalLessonNavigation(false));
  };

  return (
    <ModalSheet
      isOpen={isOpen}
      onClose={handleClose}
      initialSnap={0}
      snapPoints={[0.7]}
    >
      <div className='mb-8 flex flex-col gap-4 overflow-scroll'>
        {content &&
          content.length > 0 &&
          content.map((slide, index) => {
            const isPassedSlide = currentStep >= index;

            return (
              <NavigationCard
                key={slide.id}
                isPassedSlide={isPassedSlide}
                slide={slide}
              />
            );
          })}
      </div>
    </ModalSheet>
  );
};
