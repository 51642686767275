import { Card, Typography } from '@components/atoms';
import { getLessonTextSubtype } from '@data/utils';
import { useTranslation } from 'react-i18next';

interface NavigationCardProps {
  isPassedSlide: boolean;
  slide: Record<string, string | number>;
}

export const NavigationCard = ({
  isPassedSlide,
  slide,
}: NavigationCardProps) => {
  const { t } = useTranslation('common');

  return (
    <Card key={slide.id}>
      <div className='flex items-center gap-4'>
        <input
          type='checkbox'
          className='checkbox [--chkbg:#6E2AB1] [--chkfg:#FBFBFB]'
          checked={isPassedSlide}
          onChange={() => {}}
          onClick={() => {}}
        />
        <div className='flex flex-col'>
          <Typography variant='span' className='font-bold'>
            {slide.title}
          </Typography>
          <Typography variant='span'>
            {getLessonTextSubtype(slide.type, slide.subtype, t)}
          </Typography>
        </div>
      </div>
    </Card>
  );
};
