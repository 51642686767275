import { ReactNode } from 'react';
import { SlideTitle } from '@components/molecules';
import { ActivitySubtype, ActivityType } from '@domain/constants';

export interface SlideContentWrapperProps {
  title: string;
  children: ReactNode;
  type: ActivityType;
  subtype: ActivitySubtype;
}

export const SlideContentWrapper = ({
  title,
  children,
  type,
  subtype,
}: SlideContentWrapperProps) => {
  return (
    <div className='flex h-full flex-1 flex-col overflow-hidden'>
      <SlideTitle title={title} type={type} subtype={subtype} />
      <div className='flex-1 overflow-auto'>
        {children}
      </div>
    </div>
  );
};
